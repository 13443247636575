input:focus,
button:focus {
  outline: 0;
}
.white-lilac {
  color: #f3f6fb;
}
.dove-gray {
  color: #656464;
}
.bright-turquoise {
  color: #23e0f7;
}
.pigeon-post {
  color: #b5c5df;
}
.big-stone {
  color: #1a2c45;
}
.blue-bayox {
  color: #50617b;
}
.razzmatazz {
  color: #fb0d5b;
}
.hover--white-lilac:hover {
  color: #f3f6fb;
}
.hover--white:hover {
  color: #fff;
}
.hover-target:hover .hover--bright-turquoise,
.hover--bright-turquoise:hover {
  color: #23e0f7;
}
.hover--blue-bayox:hover {
  color: #50617b;
}
.dim {
  opacity: 1;
}
.bg-big-stone {
  background-color: #1a2c45;
}
.bg-firefly {
  background-color: #0e1d32;
}
.bg-bright-turquoise {
  background-color: #23e0f7;
}
.bg-cloud-burst {
  background-color: #233855;
}
.bg-razzmatazz {
  background-color: #fb0d5b;
}
.bg-caribbean-green {
  background-color: #02caad;
}
.b--black-stone {
  border-color: #081527;
}
.b--firefly {
  border-color: #0e1d32;
}
.b--bright-turquoise {
  border-color: #23e0f7;
}
.b--pigeon-post {
  border-color: #b5c5df;
}
.b--caribbean-green {
  border-color: #02caad;
}
.b--caribbean-green-soft {
  border-color: rgba(0,222,219,0.4);
}
.fill--current-color path,
.fill--current-color line,
.fill--current-color rect {
  fill: currentColor;
}
.stroke--current-color path,
.stroke--current-color polyline,
.stroke--current-color circle {
  stroke: currentColor;
}
.tracked--1 {
  letter-spacing: 1px;
}
.tracked--2 {
  letter-spacing: 2px;
}
.fancy-underline::after {
  content: '';
  display: block;
  background: #02caad;
  width: 100px;
  height: 1px;
  margin: 10px auto;
}
.appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.disabled,
button:disabled {
  opacity: 0.2;
}
/*# sourceMappingURL=src/index.css.map */
.Codemirror_CodeMirrorContainer__m5D59 .CodeMirror {
  color: #f3f6fb;
  background-color: #1a2c45;
  height: auto;
  min-height: calc(100vh - 220px);
}
.Codemirror_CodeMirrorContainer__m5D59 .CodeMirror pre {
  padding-left: 30px;
}
.Codemirror_CodeMirrorContainer__m5D59 .CodeMirror-scroll {
  min-height: calc(100vh - 220px);
}
.Codemirror_CodeMirrorContainer__m5D59 .CodeMirror-gutters {
  background-color: #233855;
  border-right: 0;
}
.Codemirror_CodeMirrorContainer__m5D59 .CodeMirror-lines {
  padding: 30px 0;
}
.Codemirror_CodeMirrorContainer__m5D59 .CodeMirror-linenumber {
  color: #50617b;
  padding: 0 13px 0 7px;
  font-size: 10px;
}
.Codemirror_CodeMirrorContainer__m5D59 .CodeMirror-cursor {
  border-color: #f3f6fb;
}
.Codemirror_CodeMirrorContainer__m5D59 .cm-s-default .cm-header {
  font-weight: normal;
  color: #23e0f7;
}
.Codemirror_CodeMirrorContainer__m5D59 .cm-s-default .cm-comment {
  color: #b5c5df;
}
.Codemirror_CodeMirrorContainer__m5D59 .cm-s-default .cm-variable-2,
.Codemirror_CodeMirrorContainer__m5D59 .cm-s-default .cm-quote {
  color: #f3f6fb;
}
.Codemirror_CodeMirrorContainer__m5D59 .cm-s-default .cm-link,
.Codemirror_CodeMirrorContainer__m5D59 .cm-s-default .cm-url {
  color: #23e0f7;
}
/*# sourceMappingURL=src/components/Codemirror.module.css.map */
.Preview_Preview__5SCEl {
  padding-left: 35px;
}
.Preview_Preview__5SCEl h1::before,
.Preview_Preview__5SCEl h2::before,
.Preview_Preview__5SCEl h3::before,
.Preview_Preview__5SCEl h4::before,
.Preview_Preview__5SCEl h5::before,
.Preview_Preview__5SCEl h6::before {
  font-size: 14px;
  font-weight: lighter;
  color: #b5c5df;
  display: inline-block;
  width: 0;
  margin-left: -35px;
  margin-right: 35px;
  vertical-align: baseline;
}
.Preview_Preview__5SCEl h1::before {
  content: 'H1';
}
.Preview_Preview__5SCEl h2::before {
  content: 'H2';
}
.Preview_Preview__5SCEl h3::before {
  content: 'H3';
}
.Preview_Preview__5SCEl h4::before {
  content: 'H4';
}
.Preview_Preview__5SCEl h5::before {
  content: 'H5';
}
.Preview_Preview__5SCEl h6::before {
  content: 'H6';
}
.Preview_Preview__5SCEl > :first-child {
  margin-top: 0 !important;
}
/*# sourceMappingURL=src/components/Preview.module.css.map */
